import { service } from "./request-index";
import { getToken } from "../utility/help"

const requestAll = async(instance, { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}

export const requestUserInfo = ({ bot_id, mid, role, cbSuccess , cbError }) => requestAll(
    service.get(`/api/v1/${bot_id}/user/mid/${mid}`,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/user/mid/${mid}`)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestUserRegister = ({ bot_id, formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/${bot_id}/user`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/user`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestUserUpdate = ({ bot_id, mid, formData, role, cbSuccess , cbError }) => requestAll(
    service.put(`/api/v1/${bot_id}/user/mid/${mid}`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/user/mid/${mid}`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestUserReport = ({ bot_id, formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/${bot_id}/support`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/support`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestUserRedeem = ({ bot_id, formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/${bot_id}/coupon/redeem`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/coupon/redeem`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)
